@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Unica+One&display=swap');

$font-Unicia: 'Unica One', cursive;
$font-Montserrat: 'Montserrat', sans-serif;
$color-one: #67b7bb;
$color-two: #E3FDFD;
$color-three: #A6E3E9;


html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
}

.section {
  min-height: 100vh;
}

.i {
  margin: 0 10px 10px 10px;
}

h1 {
  font-family: $font-Unicia;
  font-size: 80px;
}

h2 {
  font-size: 25px;
  font-family: $font-Unicia;
}

p {
  font-family: $font-Montserrat;
  font-size: 18px;
}

#logo-nav {
  width: 30px;
}

header {
  height: 100vh;
  canvas {
    background-color: $color-one;
  }
  .header-text {
    position: absolute;
    left: 50%;
    top: 50%;
    color: $color-two;
    font-family: $font-Unicia;
    h1 {
      font-size: 100px;
    }
    h2 {
      font-size: 50px;
      font-style: oblique;
      color: $color-three;
    }
  }
}

.navbar {
  display: grid;
  place-items: center;
  background-color: $color-one;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100vw;
  padding: 0;
  .nav-item{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-two;
    text-align: center;
    text-decoration: none;
    position: sticky;
    border-radius: 25px;
  }
  li {
    text-align: center;
    margin: 0;
    font-size: 17px;
    font-weight: bold;
    font-family: $font-Montserrat;
    color: $color-two;
    text-decoration: none;
    &:hover {
      color: rgb(255, 255, 255);
      transition: 0.3s;
      border-radius: 0px;
    }
  }
}

.about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: $color-two;
  color: $color-one;
  .section {
    display: flex;
    flex-direction: row;
    max-width: 70vw;
    justify-content: center;
    align-items: center;
  }
  .about-me {
    display: flex;
    flex-direction: column;
    width: 370px;
    margin: 40px;
    h1 {
      margin: 10px 0;
    }
  }
  .tech {
    display: flex;
    flex-direction: column;
    background-color: $color-one;
    height: 750px;
    width: 500px;
    color: $color-two;
    box-shadow: inset 0px 0px 10px 0 rgba(0, 0, 0, 0.2);
    .tech-logo {
      margin: 20px;
    }
    h1 {
      text-align: center;
      margin: 10px 0;
      margin-top: 40px;
      text-decoration: underline;
    }
    h2 {
      margin: 10px 0;
      font-family: $font-Unicia;
      font-weight: bold;
    }
    
  }
}

.projects {
  background-color: $color-one;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  h1 {
    color: $color-two;
    text-align: center;
    margin: 20px;
  }
  h2 {
    max-width: 300px;
  }
  h3 {
    font-size: 13px;
    max-width: 300px;
    color: #4d888b91;
    font-family: $font-Montserrat;
    font-weight: bold;
  }
  p {
    font-family: $font-Montserrat;
    font-weight: bold;
    font-size: 14px;
  }
  i {
    color: $color-one;
    &:hover {
      color: rgb(0, 0, 0)
    }
  }
  .git {
    display: flex;
    justify-content: center;
  }
  .iMac {
    height: 520px;
    margin: 20px;
  }
  .slide {
    position: relative;
  }
  .project-carousel {
    width: 100%;
    max-width: 588.5px;
    max-height: 400px;
    position: absolute;
    right: 40px;
    top: 7%;
  }
  img {
    margin-right: 0;
  }
  .image {
    max-height: 331px;
    margin: 0;
    margin-top: 2.5px;
  }
  .git {
    display: flex;
    flex-direction: row;
  }
  .briefs {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .project-brief {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 100px 50px;
    width: 400px;
    height: 400px;
    background-color: $color-two;
    border-radius: 200px;
    color: $color-one;
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.2);
  }
  .project-brief-title {
    float: left;
    text-align: center;
  }
  .project-brief-info {
    width: 300px;
  }
}

.xp {
  padding: 30px;
  background-color: $color-two;
  color: $color-one;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    margin-left: 200px;
    font-size: 16px;
    font-family: $font-Montserrat;
    font-weight: bold;
    text-decoration: underline;
  }
  .name {
    display: flex;
    flex-direction: row;
    margin: 220px 0 10px 200px;
  }
  .description {
    max-width: 420px;
    margin: 10px 0 0 200px;
  }
  .info {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 800px;
    height: 800px;
    background-color: $color-one;
    color: $color-two;
    box-shadow: inset 0px 0px 10px 0 rgba(0, 0, 0, 0.2);
    margin: 40px;
    border-radius: 500px;
  }
  .i {
    width: 50px;
  }
  .app {
    border-radius: 30px;
  }
  .dates {
    text-decoration: none;
  }
}

.interests {
  padding: 30px;
  background-color: $color-one;
  color: $color-two;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; 
  }
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    margin-left: 100px;
    font-size: 16px;
    font-family: $font-Montserrat;
    font-weight: bold;
    text-decoration: underline;
  }
  .name {
    display: flex;
    flex-direction: row;
    margin: 170px 0 10px 100px;
  }
  .description {
    max-width: 420px;
    margin: 10px 0 0 100px;
  }
  .info {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 600px;
    height: 600px;
    color: $color-one;
    background-repeat: no-repeat;
    background-color: $color-two;
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.2);
    margin: 100px 40px 40px 40px;
    border-radius: 500px;
  }
  .i {
    width: 50px;
  }
  .app {
    border-radius: 30px;
  }
  .dates {
    text-decoration: none;
  }
}

.contact {
  background-color: $color-two;
  color: $color-one;
  padding: 30px;
  .text {
    margin-left: 200px;
  }
  a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
    i, p {
      color: rgb(82, 118, 139);
    }
  }
  }
  i {
    margin: 10px;
    color: $color-one;
  }
  p {
    color: $color-one;
    font-weight: bolder;
    margin: 0;
    
  }
  footer {
    float: right;
    margin-right: 0 10px;
  }
}

.dot-parallax {
  background-image: url("./scroll.png");
  height: 30vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 10px 0 rgba(0, 0, 0, 0.25);
}

.line-parallax {
  background-image: url("./2.png");
  height: 30vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 10px 0 rgba(0, 0, 0, 0.25);
}

.tri-parallax {
  background-image: url("./tri.png");
  height: 30vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 10px 0 rgba(0, 0, 0, 0.25);
}

.squ-parallax {
  background-image: url("./square.png");
  height: 30vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 10px 0 rgba(0, 0, 0, 0.25);
}


@media (max-width: 616px) {
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 20px;
  }
  #home {
  background-color: $color-one;
    .header-text {
      width: 300px;
      display: grid;
      place-items: center;
      transform: translate(-50%, -50%);
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 25px;
    }
    }
  }
  .navbar {
    background-color: $color-one;
    button {
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      height: 20px;
      width: 30px;
      position: relative;
    }
    span {
      margin: 0;
      padding: 5px;
      background-size: 130%;
    }
    a {
      font-size: 13px;
    }
  }
  
  .projects {
    height: auto;
    display: grid;
    place-items: center;
    .iMac {
    display: none;
    }
    .project-carousel {
      position: static;
      margin-bottom: 10px;
      border: solid $color-two 3px;
      box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.25);
    }
    .project-brief-info {
      width: 200px;
      margin: 0;
    }
    .project-brief-title {
      margin: 0px;
    }
    .image {
      margin: 0;
      width: 340px;
    }
    .project-brief {
      margin: 10px 0;
      width: 310px;
      height: 310px;
    }
    h3 {
      font-size: 10px;
      max-width: 200px;
    }
    p {
      font-size: 10px;
    }
    i {
      font-size: 20px;
    }
  }
  
  .xp, .interests {
    display: grid;
    place-items: center;
    p {
      font-size: 10px;
    }
    h1 {
      text-align: center;
      margin: 0px;
    }
    h2 {
      font-size: 25px;
    }
    h3 {
      font-size: 10px;
      max-width: 200px;
      margin: 5px 0;
    }
    .name {
      margin: 0;
    }
    .description {
      margin: 0;
      width: 270px;
    }
    .info {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      width: 310px;
      height: 310px;
    }
    .i {
      height: 25px;
      width: 25px;
      font-size: 25px;
    }
  }

  .info {
    width: 360px;
    height: 400px;
  }

  .contact {
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 12px;
    }
    .text {
      margin-left: 10px;
    }
  }
}